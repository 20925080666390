<template>
  <div class="DialogRpoertStatusManage__Component">
    <div class="modal-content">
      <header class="modal-card-head">
        <p class="modal-card-title">ניהול סטטוס דוח</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="DialogRpoertStatusManage__Component Table">
          <div class="columns">
            <div class="column" style="width: 200px">תאריך</div>
            <div class="column">סטטוס</div>
            <div class="column" style="width: 100px">סכום חדש</div>
            <div class="column" style="width: 200px">מקור</div>
            <div class="column" style="width: 50px"></div>
          </div>
          <div class="row" v-for="(item, index) in model" :key="item.Date">
            <div>{{ item.Date | date }}</div>
            <div>{{ item.Status.Name }}</div>
            <div>₪{{ item.Amount }}</div>
            <div>{{ item.Origin && item.Origin.Name }}</div>
            <div>
              <a class="removeItem" href="" @click.prevent="removeItem(index)"
                >הסר</a
              >
            </div>
          </div>
          <div
            class="row"
            v-for="(item, index) in $v.newItemModel.$each.$iter"
            :key="index"
          >
            <div class="Table_Field" :class="{ invalid: item.Date.$error }">
              <b-datepicker
                v-model="item.Date.$model"
                icon-prev="chevron-right"
                icon-next="chevron-left"
                icon="calendar-today"
                locale="en-GB"
                :day-names="['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש']"
                :month-names="[
                  'ינואר',
                  'פברואר',
                  'מרץ',
                  'אפריל',
                  'מאי',
                  'יוני',
                  'יולי',
                  'אוגוסט',
                  'ספטמבר',
                  'אוקטובר',
                  'נובמבר',
                  'דצמבר',
                ]"
              />
            </div>
            <div class="Table_Field" :class="{ invalid: item.Status.$error }">
              <v-select
                dir="rtl"
                label="Text"
                :options="transfetStatuses"
                :clearable="true"
                placeholder="בחר סטטוס"
                :reduce="(item) => item.Value"
                v-model="item.Status.$model"
              >
                <div slot="no-options">לא נמצאו ערכים דומים</div>
              </v-select>
            </div>
            <div class="Table_Field" :class="{ invalid: item.Amount.$error }">
              <b-input
                placeholder="₪250"
                type="text"
                @input="this.value = this.value.replace(/[^0-9]/g, '')"
                v-model="item.Amount.$model"
              ></b-input>
            </div>
            <div class="Table_Field" :class="{ invalid: item.Origin.$error }">
              <v-select
                dir="rtl"
                label="Text"
                :options="transfetStatusesOrigins"
                :clearable="true"
                placeholder="בחר מקור"
                :reduce="(item) => item.Value"
                v-model="item.Origin.$model"
              >
                <div slot="no-options">לא נמצאו ערכים דומים</div>
              </v-select>
            </div>
            <div>
              <a
                class="removeItem"
                href=""
                @click.prevent="removeNewItem(index)"
                >הסר</a
              >
            </div>
          </div>
        </div>
        <h1 class="no-records" v-if="!model.length && !newItemModel.length">
          הוסף שורה כדי להוסיף סטטוס
        </h1>
      </section>
      <footer class="modal-card-foot">
        <div>
          <b-button
            :loading="isLoading"
            @click="save"
            label="שמור"
            type="is-primary"
          />
          <b-button
            :disabled="isLoading"
            label="סגור"
            @click="$emit('close')"
          />
        </div>
        <!-- <div>
          <a href="" @click.prevent="addRow">הוסף שורה</a>
        </div> -->
      </footer>
    </div>
  </div>
</template>

<script>
import ReportStatusesService from "@/services/ReportStatusesService";
import ReportStatusOriginsService from "@/services/ReportStatusOriginsService";
import ReportService from "@/services/ReportService";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import { ToastProgrammatic as Toast } from "buefy";
import { required } from "vuelidate/lib/validators";
import store from "../../store";
import datesValidator from "../../validators/dates";

export default {
  name: "DialogReportDriverAssign",
  props: ["reports"],
  components: {
    vSelect,
  },
  computed: {
    // Id: () => store.state.report,
    Id: () => store.state.report.Id,
    StatusEvents: () => store.state.report.StatusEvents,
  },
  validations: {
    newItemModel: {
      $each: {
        Date: {
          required,
          isValidDate: datesValidator.isValidDate,
        },
        Amount: {
          required,
          isANumber: datesValidator.isStringPresentsANumber,
        },
        Status: {
          required,
        },
        Origin: {
          required,
        },
      },
    },
  },
  data() {
    return {
      submitted: false,
      isLoading: false,
      statusId: null,
      reference: null,
      model: [],
      transfetStatuses: [],
      transfetStatusesOrigins: [],
      newItemModel: [],
      //   newItemModel: {
      //     Date: null,
      //     Status: null,
      //     Amount: null,
      //     Origin: null,
      //   },
    };
  },
  created() {
    ReportStatusesService.get().then((r) => {
      this.transfetStatuses = r;
    });

    ReportStatusOriginsService.get().then((r) => {
      this.transfetStatusesOrigins = r;
    });

    console.log("StatusEvents:", this.StatusEvents);
    this.model = this.StatusEvents.slice(0);
    this.addRow();
  },
  methods: {
    addRow() {
      this.newItemModel.push({
        Date: null,
        Status: null,
        Amount: null,
        Origin: null,
      });
    },
    removeItem(index) {
      this.model.splice(index, 1);
    },
    removeNewItem(index) {
      this.newItemModel.splice(index, 1);
    },
    setSelected(value) {
      this.statusId = value;
    },
    // eslint-disable-next-line consistent-return
    save() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return Toast.open({
          type: "is-warning",
          message: "סטטוסים לא תקינים!",
          duration: 8000,
        });
      }
      this.isLoading = true;

      const data = this.model.slice(0).concat(
        this.newItemModel.map((i) => ({
          Date: i.Date,
          Status: { Id: i.Status },
          Origin: { Id: i.Origin },
          Amount: i.Amount,
        }))
      );
      // const statusesToSet = {
      //   ReportIds: this.reports,
      //   Status: data[0].Status.Id,
      // };
      ReportService.setStatus(this.Id, data)
        .then((r) => {
          this.$emit("close");
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
          store.commit("report/storeReportDetails", { StatusEvents: r.data });
        })
        .catch(() => {
          Toast.open({
            type: "is-danger",
            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.DialogRpoertStatusManage__Component {
  display: table;
  border-collapse: collapse;
  width: 100%;
  width: 1000px;
  .modal-card-body {
    min-height: 500px;
  }
  .columns {
    font-weight: bold;
  }

  .columns,
  .row {
    display: table-row;
    border-bottom: 1px solid #d2d2d2;
    margin: 0;

    .removeItem {
      text-decoration: underline;
    }

    & > div {
      display: table-cell;
      vertical-align: middle;
      padding: 15px 10px;
    }

    .Table_Field {
      &.invalid {
        background-color: #e9f5ff;
      }

      ::v-deep {
        .datepicker {
          font-size: unset;

          .dropdown-menu {
            left: unset;
            right: 0;
          }
        }
      }
    }
  }

  .modal-card-foot {
    display: flex;
    justify-content: space-between;
  }

  .no-records {
    font-size: 18px;
    text-align: center;
    margin: 20px 0;
  }
}
</style>
