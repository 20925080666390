<template>
  <div class="DialogReportPaid__Component">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">שיוך נהג</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <h1>נא לבחור את הנהג אשר ישוייך אל הדוחות המסומנים</h1>
        <div class="DialogReportPaid__Component__Loading">
          <div v-if="isLoadingDriversList">
            <b-loading
              :is-full-page="false"
              v-model="isLoadingDriversList"
              :can-cancel="false"
            />
          </div>
          <v-select
            dir="rtl"
            label="Text"
            :options="data"
            append-to-body
            :clearable="true"
            :placeholder="'בחר נהג מהרשימה'"
            :reduce="(item) => item.Value"
            @input="setSelected"
            v-model="driverId"
          >
            <div slot="no-options">לא נמצאו ערכים דומים</div>
          </v-select>
        </div>
        <b-field class="mt-5" v-if="driverId">
          <b-checkbox v-model="isDriverPaid"
            ><span class="mr-2">הנהג ישלם את הדוח</span></b-checkbox
          >
        </b-field>
        <b-field class="mt-5" v-if="driverId">
          <b-checkbox v-model="showAmount"
            ><span class="mr-2">סמן על מנת להזין סכום</span></b-checkbox
          >
        </b-field>
        <b-field v-if="showAmount && driverId" class="mt-5">
          <b-input placeholder="סכום" type="number" v-model="amount" min="1">
          </b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button
          :loading="isLoading"
          @click="save"
          label="שמור"
          type="is-primary"
        />
        <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
      </footer>
    </div>
  </div>
</template>

<script>
import DriverService from "@/services/DriverService";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import { ToastProgrammatic as Toast } from "buefy";

export default {
  name: "DialogReportDriverAssign",
  props: ["reports", "entityId", "currentDriver", "onSuccess"],
  components: {
    vSelect,
  },
  computed: {
    items() {
      return this.reports ?? this.entityId;
    },
  },
  created() {
    DriverService.get(true).then((r) => {
      this.isLoadingDriversList = false;
      this.data = r;
      if (this.items.length === 1 && this.currentDriver && this.currentDriver.driverId) {
        this.driverId = this.currentDriver.driverId;
        this.isDriverPaid = this.currentDriver.isDriverPaid;
        this.showAmount = this.currentDriver.showAmount;
        this.amount = this.currentDriver.amount;
      }
    });
  },
  data() {
    return {
      isLoading: false,
      isLoadingDriversList: true,
      driverId: null,
      isDriverPaid: false,
      showAmount: false,
      amount: null,
      data: [],
    };
  },
  methods: {
    setSelected(value) {
      this.driverId = value;
    },
    save() {
      this.isLoading = true;
      const data = this.items.map((r) => ({
        ReportId: r,
        DriverId: this.driverId === 0 ? null : this.driverId,
        IsPaidByDriver: this.driverId && this.isDriverPaid,
        DriverAmount:
          this.isDriverPaid && this.showAmount && this.driverId
            ? this.amount
            : null,
      }));
      DriverService.setReportDriver(data)
        .then(() => {
          this.$emit("close");
          if (this.onSuccess) this.onSuccess();
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
        })
        .catch(() => {
          Toast.open({
            type: "is-danger",
            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
</style>
