import moment from 'moment';

const isValidDate = (value) => moment(value).isValid();
const isStringPresentsANumber = (value) => {
    if (typeof value !== 'string') return false;
    return !Number.isNaN(value) && !Number.isNaN(parseFloat(value));
};

export default {
    isValidDate,
    isStringPresentsANumber,
};
